import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/EcoClub/evdayprimary/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/evdayprimary/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/evdayprimary/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/evdayprimary/4.jpg';
// import p5 from 'assests/Photos/Clubs/EcoClub/evdayprimary/5.jpg';
// import p6 from 'assests/Photos/Clubs/EcoClub/evdayprimary/6.jpg';
// import p7 from 'assests/Photos/Clubs/EcoClub/evdayprimary/7.jpg';
// import p8 from 'assests/Photos/Clubs/EcoClub/evdayprimary/8.jpg';
// import p9 from 'assests/Photos/Clubs/EcoClub/evdayprimary/9.jpg';
// import p10 from 'assests/Photos/Clubs/EcoClub/evdayprimary/10.jpg';
// import p11 from 'assests/Photos/Clubs/EcoClub/evdayprimary/11.jpg';
// import p12 from 'assests/Photos/Clubs/EcoClub/evdayprimary/12.jpg';

import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';


const Evday_Preprimary_2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/8.webp`;
  const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/9.webp`;
  const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/10.webp`;
  const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/11.webp`;
  const p12 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/classpre-primary/12.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 2,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 2,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 2,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
    {
        src: p6,
        source: p6,
        rows: 1,
        cols: 2,
    },
    {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
    },
    {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
    },
    {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
    },
    {
        src: p10,
        source: p10,
        rows: 1,
        cols: 1,
    },
    {
        src: p11,
        source: p11,
        rows: 1,
        cols: 1,
    },
    {
        src: p12,
        source: p12,
        rows: 1,
        cols: 1,
    },


  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      ENVIRONMENT DAY
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
       Class : PRE-PRIMARY   &   Date :  30 JUNE 2023
        </Typography>

        <br />
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Nature constantly teaches you to grow”

<br/>
<br></br>
        </Typography>
        <Typography variant={'subtitle1'} align={'justify'}>
        The above statement was much sought after by the students and teachers of Pre-Primary at
National Public School Yeshwanthpur this Environment Day on Friday, 30 June 2023.
<br></br>
Teachers enlightened the students about the importance of environment day and the reasons for the celebration. There were 
three varieties of activities planned for the students. The first activity for the day was with water plants or hydroponic 
plants. Students first planted a money plant into glass containers and hung them around the plant stand. Then they planted
the saplings of flowering plants into the decorative plant boxes provided to them and watered them. They were introduced to 
the concept of air plants too.
<br></br>
Students eagerly participated by bringing eco-friendly containers from their homes. They filled these with mud and sowed 
few seeds of flowering plants with love and care. These containers were neatly arranged in a row. The students were 
exuberant and in high spirits during the entire programme. They are eagerly waiting for the seeds to sprout that showcases 
their effort to preserve the environment. Students relished eating healthy snacks using sprouts to make the day a 
memorable one.
<br></br>
Students and teachers took the initiative of making an eco-friendly bird feeder using recycled materials. A section in the 
bird feeder was allotted for food and another for water. The bird feeder was hung in a designated place in the school 
garden. Students observed in awe when the birds visited this feeder and pecked on the grains and quenched their thirst. 
It was truly a sight to cherish.
<br></br>
This activity-based fun learning created an awareness in young minds to take care of the flora and fauna, plant more trees 
and to work towards a better and healthier environment for all of us to live in. This little step will definitely go a 
long way to heal Mother Earth.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Life is beautiful and possible only on Earth, so let us Protect it.” 

<br/>
        </Typography>

        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
It’s not yours, not mine. It’s ours !! Help protect our Mother Earth !

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Ecoclub2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Evday_Preprimary_2023;